import React, { Suspense, lazy, useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  miscellaneous as appService,
  auth as authService,
  users as userServices,
} from '@coligo-org/fe-common/services';

import { Snackbar } from '@mui/material';

import useRoles from 'hooks/useRoles';
import useCachedService from 'hooks/useCachedService';
import {
  courses as CoursesActions,
  users as UsersActions,
  app as AppActions,
} from '../reduxConfig/stateActions';
import requireAuth from './requireAuth';
import getDefaultRoute from './helpers/getDefaultRoute';

import ColigoLoading from '../common/components/ColigoLoading';
import { refreshUserData } from '../auth/state/actions/auth';
import { getCurrentUser } from '../auth/services/authService';
import useEventListener from '../hooks/useEventListener';
import Confirmation from './modules/common/users/views/EmailVerification/Confirmation';

const SuperAdminLayout = lazy(
  () => import('./modules/super-admin/SuperAdminLayout'),
);
const OrganizationAdminLayout = lazy(
  () => import('./modules/org-admin/OrganizationAdminLayout'),
);
const SchoolAdminLayout = lazy(
  () => import('./modules/school-admin/SchoolAdminLayout'),
);
const PrincipalLayout = lazy(
  () => import('./modules/principal/PrincipalLayout'),
);
const HODLayout = lazy(() => import('./modules/hod/HODLayout'));
const TeacherLayout = lazy(() => import('./modules/teacher/TeacherLayout'));
const StudentLayout = lazy(() => import('./modules/student/StudentLayout'));
const ParentLayout = lazy(() => import('./modules/parent/ParentLayout'));

const staffGuideLink =
  'https://coligo.freshdesk.com/support/solutions/67000218134';
const adminsGuideLink =
  'https://coligo.freshdesk.com/support/solutions/67000218135';
const parentGuideLink =
  'https://coligo.freshdesk.com/support/solutions/67000218131';
const studentGuideLink =
  'https://coligo.freshdesk.com/support/solutions/67000218130';

function App() {
  const [isOnline, setIsOnline] = useState(true);
  const { isSwitchingAccount } = useSelector(state => state.app.state);
  const { isGettingUser } = useSelector(
    state => state.users.state.isGettingUser,
  );
  const user = useSelector(state => state.auth.user);
  const selectedChild = useSelector(
    state => state.parent.children.selectedChild,
  );
  const constantsRedux = useSelector(state => state.app.constants);
  const { isStudent, isParent, isAdmin, allRoles } = useRoles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getHiddenGrades = (...args) =>
      dispatch(CoursesActions.getHiddenGrades(...args));
    const getUser = (...args) => dispatch(UsersActions.getUser(...args));

    if (isParent && selectedChild._id) {
      getHiddenGrades({
        userId: selectedChild._id,
      });
    } else if (!isAdmin && user._id) {
      getHiddenGrades({
        userId: user._id,
      });
    }

    getUser({});
  }, [user, selectedChild, isStudent, isParent, dispatch, isAdmin]);

  useEffect(() => {
    const checkValidJWT = async () => {
      const isOldVersionOfJWT = !authService.checkIsValidJWTVersion(
        localStorage.getItem('token'),
      );

      if (isOldVersionOfJWT) {
        await authService
          .refreshToken({
            userName: user.username,
          })
          .then(() => {
            const newJWT = localStorage.getItem('token');
            if (authService.checkIsValidJWTVersion(newJWT)) {
              dispatch(refreshUserData());
              navigate(`${getDefaultRoute(getCurrentUser(), allRoles)}`);
            } else {
              // not valid jwt even after refreshing
              window.location.replace('/logout?&expired=1');
            }
          })
          .catch(() => {
            window.location.replace('/logout?&expired=1');
          });
      }
    };
    checkValidJWT();
  }, [allRoles, dispatch, navigate, user.username]);

  const setOnline = useCallback(() => setIsOnline(true), []);
  const setOffline = useCallback(() => setIsOnline(false), []);

  useEventListener('online', setOnline);
  useEventListener('offline', setOffline);

  const defaultRoute = getDefaultRoute(user, allRoles);

  const { isLoading: isLoadingConstants, data: constants } = useCachedService({
    key: 'appService.getAppConstants',
    fetcher: () => appService.getAppConstants(),
  });
  const { data: featureFlags } = useCachedService({
    key: ['userServices.getFeatureFlags', user._id],
    fetcher: () => userServices.getFeatureFlags(),
  });
  const { data: messagesSettings } = useCachedService({
    key: [
      'usersServices.getUserMessageSettings',
      isParent ? selectedChild._id : user._id,
    ],
    fetcher: () =>
      userServices.getUserMessageSettings({
        params: {
          ...(isParent && { childId: selectedChild._id }),
        },
      }),
  });

  useEffect(() => {
    if (constants) dispatch(AppActions.setConstants(constants));
    if (featureFlags) dispatch(AppActions.setFeatureFlags(featureFlags));
    dispatch(AppActions.setMessageSettings(messagesSettings || []));
  }, [constants, dispatch, featureFlags, messagesSettings]);

  return isLoadingConstants ||
    isSwitchingAccount ||
    Object.keys(constantsRedux).length === 0 ||
    isGettingUser ? (
    <ColigoLoading />
  ) : (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!isOnline}
        message="You are offline, Please check your internet access"
      />
      <Suspense
        fallback={
          <center>
            <ColigoLoading />
          </center>
        }
      >
        <Routes>
          <Route
            path="/super-admin/*"
            element={<SuperAdminLayout guideLink={adminsGuideLink} />}
          />
          <Route
            path="/org-admin/:organizationId/*"
            element={<OrganizationAdminLayout guideLink={adminsGuideLink} />}
          />
          <Route path="/org-admin/*" element={<Navigate to={defaultRoute} />} />
          <Route
            path="/school-admin/:schoolId/*"
            element={<SchoolAdminLayout guideLink={adminsGuideLink} />}
          />
          <Route
            path="/school-admin/*"
            element={<Navigate to={defaultRoute} />}
          />
          <Route
            path="/principal/*"
            element={<PrincipalLayout guideLink={staffGuideLink} />}
          />
          <Route
            path="/head-of-department/*"
            element={<HODLayout guideLink={staffGuideLink} />}
          />
          <Route
            path="/teacher/*"
            element={<TeacherLayout guideLink={staffGuideLink} />}
          />
          <Route
            path="/student/*"
            element={<StudentLayout guideLink={studentGuideLink} />}
          />
          <Route
            path="/parent/*"
            element={<ParentLayout guideLink={parentGuideLink} />}
          />
          <Route path="/verify/email" element={<Confirmation />} />
          <Route path="/*" element={<Navigate to={defaultRoute} />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default requireAuth(App);
