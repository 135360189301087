import { combineReducers } from 'redux';

import constants from './reducers/constants';
import state from './reducers/state';
import featureFlags from './reducers/featureFlags';
import messageSettings from './reducers/messageSettings';

export default combineReducers({
  constants,
  state,
  featureFlags,
  messageSettings,
});
