import setConstants from './loadAppConstants';
import setFeatureFlags from './loadFeatureFlags';
import setMessageSettings from './loadMessageSettings';

const actions = {
  setConstants,
  setFeatureFlags,
  setMessageSettings,
};
export default actions;
